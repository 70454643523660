@layer base {
  body,
  html {
    @apply font-futuraPT bg-gray-300 text-gray-600;
  }

  body,
  html,
  #root {
    @apply h-full;
  }

  #root {
    @apply text-lg;
  }

  pre, code {
    @apply font-futuraPT;
  }

  a {
     @apply underline text-gray-600;
  }
}
