@layer components {
  .cr-caret-up {
    @apply inline-block w-0 h-0;

    border-left: 0.33em solid transparent;
    border-right: 0.33em solid transparent;
    border-bottom: 0.33em solid currentColor;
  }

  .cr-caret-down {
    @apply inline-block w-0 h-0;

    border-left: 0.33em solid transparent;
    border-right: 0.33em solid transparent;
    border-top: 0.33em solid currentColor;
  }

  .cr-caret-right {
    @apply inline-block w-0 h-0;

    border-top: 0.33em solid transparent;
    border-bottom: 0.33em solid transparent;
    border-left: 0.33em solid currentColor;
  }

  .cr-caret-left {
    @apply inline-block w-0 h-0;

    border-top: 0.33em solid transparent;
    border-bottom: 0.33em solid transparent;
    border-right: 0.33em solid currentColor;
  }
}
