@layer components {
  .cr-gradient-brand-horizontal {
    @apply bg-gradient-to-r from-primary to-primary-purple;
  }

  .cr-gradient-brand-vertical {
    @apply bg-gradient-to-b from-secondary to-secondary-orange;
  }

  .cr-gradient-brand-left svg {
    @apply text-secondary;
  }

  .cr-gradient-brand-left {
    @apply relative;

    &::before {
      content: '';
      @apply absolute w-1 h-full left-0 cr-gradient-brand-vertical;
    }
  }
}
