@import url('https://use.typekit.net/bys2kys.css');

/* custom */
@import './base/base.css';
@import './base/recharts.css';
@import './components/caret.css';
@import './components/gradient.css';
@import './utilities/svg.css';

/* tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;
